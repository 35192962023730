@charset 'UTF-8';

// Font family
//

// Windows10 向けに游ゴシック体を設定する
@font-face {
  font-family: 'Original Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 100;
}
@font-face {
  font-family: 'Original Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 200;
}
@font-face {
  font-family: 'Original Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 300;
}
@font-face {
  font-family: 'Original Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 400;
}
@font-face {
  font-family: 'Original Yu Gothic';
  src: local('Yu Gothic Bold');
  font-weight: bold;
}

$font-family: 'YakuHanJPs', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Hiragino Sans', 'Noto Sans CJK JP', 'Original Yu Gothic', 'Yu Gothic', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Sans Emoji';
//$font_family: 'YakuHanJPs', -apple-system  BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, arial, sans-serif;
//$font_family: -apple-system  BlinkMacSystemFont, sans-serif;



// body の text color
//
$body_text_color: #333 !default;



// body の background-color
//
$background_body: #fff !default;



// body の文字サイズ
//
$base_font_size: 1rem !default; // default font size 1rem = 10px

$base_font_size_array: (
  sm: $base_font_size * 1.4,
  md: $base_font_size * 1.5,
  lg: $base_font_size * 1.6,
) !default;



// body の letter_spacing（単位は自由）
//
$body_letter_spacing: (
  sm: 0,
  md: 0,
  lg: 0
) !default;



// body の line-height
//
$base_line_height: (
  sm: 1.4,
  md: 1.6,
  lg: 1.6
) !default;



// リンクのテキストカラー
//
$link_color: #0033cc !default;



// アンダーライン
//
$link_decoration: none !default;



// ホバー時のテキストカラー
//
$link_hover_color: darken( $link_color, 15% ) !default;



// ホバー時のアンダーライン
//
$link_hover_decoration: none !default;



// horizontal line
//

// ボーダーの種類
//
$hr_border: 1px solid $gray-400 !default;



// ボーダー上下の間隔
//
$hr_margin: (
  'sm' : ( 'top' : .5em, 'bottom' : 1em ),
  'md' : ( 'top' : .5em, 'bottom' : 1em ),
  'lg' : ( 'top' : .5em, 'bottom' : 1em )
) !default;



// fonts directory path
//
$font_directory_path: '../webfonts';
