@charset 'UTF-8';

/**
*    Original CSS@frameworks
*
*    @since 2020.3.11
*/



// 関数
@import 'functions';

// 変数
@import 'variables/variables';

// Mixin
@import 'mixin';

// 初期設定
@import 'initialize';

// レイアウト
@import 'layout';

// grid
@import 'grid';

// flex
@import 'flex';

// header
@import 'header';

// footer
@import 'footer';

// copyright
@import 'copyright';

// typography
@import 'typography';

// icon
@import 'icon';

// button
@import 'button';

// burger
@import 'burger';

// navigation
@import 'navigation';

// breadlist
@import 'breadlist';

// label
@import 'label';

// table
@import 'table';

// form
@import 'form';

// list
@import 'list';

// alert
@import 'alert';

// block
@import 'block';

// accordion
@import 'accordion';

// tab
@import 'tab';

// pagination
@import 'pagination';

// pagetop
@import 'pagetop';

// utilities
@import 'utilities';

// adjoin
@import 'adjoin';

// pages
@import 'pages';

// fontawesome v5.12.1 使用ファイル 'variables/_var_icon.scss', '_function.scss', '_icon.scss', '_button.scss', '_list.scss'
@import 'fonts/fontawesome/fontawesome';

// 約物半角（〈〉《》「」『』【】〔〕（）［］｛｝）yakuhanjp にすると約物全部（、。！？〈〉《》「」『』【】〔〕・（）：；［］｛｝
@import 'fonts/yakuhanjp/yakuhanjp_s';
