@charset 'UTF-8';

// footer
//
footer {
  background: transparent;
  flex: none;
  padding-top: map-get( $footer_padding_top_array, sm );
  width: 100%;
  z-index: map-get( $z_index_array, footer );
  @include media(md) {
    padding-top: map-get( $footer_padding_top_array, md );
  }
  @include media(lg) {
    padding-top: map-get( $footer_padding_top_array, lg );
  }
}



// footer-wrapper
//
.footer-wrapper {
  background: $background_footer;
  border-top: 1px solid $gray-200;
  position: relative;
  padding: 0 map-get( $footer_container_padding_array, sm );
  @include media(md) {
    padding: 0 map-get( $footer_container_padding_array, md );
  }
  @include media(lg) {
    padding: 0 map-get( $footer_container_padding_array, lg );
  }
  @include width_wrapper( $footer_container_width_array, $footer_container_padding_array );
}



// footer-container
//
.footer-container {
  margin: 0 auto;
  @include width_container( $footer_container_width_array );
}



// footer-inner
//
.footer-inner {
  display: flex;
  font-size: .8em;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
}



// footer-inner-left
// footer-inner に内包して使用する
//
.footer-inner-left {
  flex: 0 0 100%;
  width: 100%;
  @include media(md){
    flex: 0 0 50%;
    width: 50%;
  }
}



// footer-inner-right
// footer-inner に内包して使用する
//
.footer-inner-right {
  flex: 0 0 100%;
  width: 100%;
  @include media(md){
    flex: 0 0 50%;
    width: 50%;
    text-align: right;
  }
}



// footer-title
//
.footer-title {
  font-size: .8em;
  font-weight: bold;
  @include media(md) {
    font-size: 1em;
  }
  @include media(lg) {
    font-size: 1em;
  }
  a {
    color: $body_text_color;
  }
}
