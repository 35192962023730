@charset 'UTF-8';

// common-wrapper
//
.common-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}



// common-column
//
.common-column {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  min-height: 100vh;
  header, footer {
    flex: none;
  }
  main {
    width: 100%;
    flex: 1 0 auto;
    min-height: 0%; // IE11用
  }
}
// safari hack
_::-webkit-full-page-media, _:future, :root main {
  flex: 1;
}



// container-wrapper
//
.container-wrapper {
  background: $background_container_wrapper;
  padding: 0 map-get( $container_padding_array, sm );
  @include media(md) {
    padding: 0 map-get( $container_padding_array, md );
  }
  @include media(lg) {
    padding: 0 map-get( $container_padding_array, lg );
  }
  @include width_wrapper( $container_width_array, $container_padding_array );
}



// container
//
.container {
  background: $background_container;
  margin: 0 auto;
  @include width_container( $container_width_array );
}
// IE11 hack
//
_:-ms-lang(x), .container { flex: 0; flex-flow: column wrap; }



// section-inner
//
.section-inner {
  padding-top: map-get( $section_gutter_array, sm );
  padding-bottom: map-get( $section_gutter_array, sm );
  @include media(md) {
    padding-top: map-get( $section_gutter_array, md );
    padding-bottom: map-get( $section_gutter_array, md );
  }
  @include media(lg) {
    padding-top: map-get( $section_gutter_array, lg );
    padding-bottom: map-get( $section_gutter_array, lg );
  }
}



.section-inner-upper {
  padding-bottom: map-get( $section_gutter_array, sm );
  @include media(md) {
    padding-bottom: map-get( $section_gutter_array, md );
  }
  @include media(lg) {
    padding-bottom: map-get( $section_gutter_array, lg );
  }
}



.section-inner-lower {
  padding-top: map-get( $section_gutter_array, sm );
  @include media(md) {
    padding-top: map-get( $section_gutter_array, md );
  }
  @include media(lg) {
    padding-top: map-get( $section_gutter_array, lg );
  }
}



// element
//
.element {
  padding-top: map-get( $element_gutter_array, sm );
  @include media(md) {
    padding-top: map-get( $element_gutter_array, md );
  }
  @include media(lg) {
    padding-top: map-get( $element_gutter_array, lg );
  }
}
