@charset 'UTF-8';

// .footer-container の横幅
// .container と合わせたい場合は $container_width_array を使用する
//
$footer_container_width_array: (
  'sm': map-get( $container_width_array, sm ),
  'md': map-get( $container_width_array, md ),
  'lg': map-get( $container_width_array, lg )
) !default;



// .footer-container の左右の padding
// .container と合わせたい場合は $container_padding_array を使用する
//
$footer_container_padding_array: (
  'sm': map-get( $container_padding_array, sm ),
  'md': map-get( $container_padding_array, md ),
  'lg': map-get( $container_padding_array, lg )
) !default;



// main と footer の間隔
//
$footer_padding_top_array: (
  sm: 0,
  md: 0,
  lg: 0
) !default;



// footer の background-color
//
$background_footer: $white-color !default;
