@charset 'UTF-8';

// heading initialize
//
h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}



// heading
//
h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}



// paragraph
//
p {
  font-size: inherit;
  letter-spacing: map-get( $p_letter_spacing_array, sm );
  line-height: map-get( $p_line_height_array, sm );
  text-rendering: optimizeLegibility;
  @include media(md) {
    letter-spacing: map-get( $p_letter_spacing_array, md );
    line-height: map-get( $p_line_height_array, md );
  }
  @include media(lg) {
    letter-spacing: map-get( $p_letter_spacing_array, lg );
    line-height: map-get( $p_line_height_array, lg );
  }
}



// 主に paragraph を段落として使用しない場合に margin-bottom を打ち消す
//
.phrase {
  margin-bottom: 0 !important;
}



// イタリック
//
i {
  font-style: italic;
  line-height: inherit;
}



// ボールド
//
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}



// code
//
pre {
  background: $gray-100;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  background: transparent;
  color: $gray-700;
  font-family: 'SourceHanCodeJP-Regular', monospace;
  font-size: .8em;
  font-weight: bold;
  vertical-align: initial;
}
