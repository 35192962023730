@charset 'UTF-8';

// 各項目の間隔
//
.form-field + .form-field {
  margin-top: map-get( $form_field_margin_array, sm );
  @include media(md) {
    margin-top: map-get( $form_field_margin_array, md );
  }
  @include media(lg) {
    margin-top: map-get( $form_field_margin_array, lg );
  }
}



// label
//
label {
  display: block;
  &.item {
    border-left: 6px solid $base-color;
    color: $gray-700;
    font-size: .9em;
    font-weight: bold;
    margin-bottom: .8em;
    padding-left: .6em;
  }
  // jquery.validate.min.js エラー表示用
  &.error {
    display: inline-block;
  }
}



// input & textarea
//
input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']),
textarea {
  background: $white-color;
  border: 1px solid $gray-400;
  box-shadow: none;
  color: $body_text_color;
  font-size: 1em;
  padding: .8em 1em;
  line-height: 1;
  width: 100%;
  transition: border-color $transition, background $transition;

  &:focus {
    background: $white-color;
    border-color: $base-color;
    box-shadow: 0 0 6px 1px rgba( $base-color, .075 );
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  &::-moz-placeholder {
    color: $gray-600;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $gray-600;
  }
  &::-webkit-input-placeholder {
    color: $gray-600;
  }
  &::-ms-expand {
    background: $white-color;
    border: 0;
  }
}

textarea {
  line-height: 1.6;
  height: auto;
  padding: 1em;
}



// select
//
select {
  width: 100%;
  &::-moz-focus-inner {
    border: 0;
  }
}

.select {
  display: inline-block;
  position: relative;
  select {
    background: $white-color;
    border: 1px solid $gray-400;
    border-radius: 4px;
    color: inherit;
    cursor: pointer;
    display: block;
    font-size: 1em;
    line-height: 1;
    padding: .8em 2.5em .7em 1em;
    position: relative;
    outline: none;
    overflow: hidden;
  }
  // 矢印
  &:after {
    border: 1px solid $gray-600;
    border-right: 0;
    border-top: 0;
    content: '';
    display: block;
    margin-top: -3px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: rotate(-45deg) translateY(-50%);
    width: 9px;
    height: 9px;
  }
}

// radio button & checkbox
//
.checkbox-group,
.radio-group {
  display: inline-block;
  // 横並び
  &.row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .label-checkbox + .label-checkbox,
    .label-radio + .label-radio {
      margin-left: 1em;
    }
  }
}

.label-checkbox input[type='checkbox'],
.label-radio input[type='radio'] {
  cursor: pointer;
}

.label-checkbox,
.label-radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  .lever {
    padding-left: .4em;
  }
}

// submit button
//
input[type='submit'] {
  @extend .button;
}
