@charset 'UTF-8';

// wrapper
// -------
.global-navigation {
  position: relative;
}

// navigation
// ----------
