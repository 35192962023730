@charset 'UTF-8';

// transition
// タイミングを統一したい場合に使用
$transition: .2s ease-out;



// ketframs

// fade in
//
@keyframes show {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}
