@charset 'UTF-8';

// header
//
header {
  background: transparent;
  padding-bottom: map-get( $header_padding_bottom_array, sm );
  width: 100%;
  z-index: map-get( $z_index_array, header );
  @include media(md) {
    padding-bottom: map-get( $header_padding_bottom_array, md );
  }
  @include media(lg) {
    padding-bottom: map-get( $header_padding_bottom_array, lg );
  }
}



// header-wrapper
//
.header-wrapper {
  background: $background_header;
  border-bottom: 1px solid $gray-200;
  position: relative;
  padding: 0 map-get( $header_container_padding_array, sm );
  @include media(md) {
    padding: 0 map-get( $header_container_padding_array, md );
  }
  @include media(lg) {
    padding: 0 map-get( $header_container_padding_array, lg );
  }
  @include width_wrapper( $header_container_width_array, $header_container_padding_array );
}



// header-container
//
.header-container {
  margin: 0 auto;
  @include width_container( $header_container_width_array );
}



// header-inner
//
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: map-get( $header_height_array, sm );
  @include media(md) {
    height: map-get( $header_height_array, md );
  }
  @include media(lg) {
    height: map-get( $header_height_array, lg );
  }
}



// header-inner-left
// header-inner に内包して使用する
//
.header-inner-left {
  flex: auto;
}



// header-inner-right
// header-inner に内包して使用する
//
.header-inner-right {
  flex: auto;
}



// header-title
//
.header-title {
  font-size: 1em;
  font-weight: bold;
  @include media(md) {
    font-size: 1em;
  }
  @include media(lg) {
    font-size: 1em;
  }
  a {
    color: $body_text_color;
  }
}
